<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Compare the number of signals in the spectrum and the number of carbons from the molecular
        formula for compound B.
      </p>
      <p class="mb-2">Which of the given statements is true?</p>

      <p class="mb-2">
        Based on the UN/DBE you calculated, could there be a double bond in compound B?
      </p>
      <v-radio-group v-model="inputs.input1" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsAShuffled"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        Evaluate the spectrum for compound A based on what you already know from the UN/DBE and IR
        spectrum.
      </p>
      <p class="mb-2">
        Is the
        <stemble-latex content="$^{13}\text{C}$" />
        spectrum consistent with the presence of an alcohol functional group?
      </p>
      <v-radio-group v-model="inputs.input2" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio v-for="option in optionsB" :key="option.text" :value="option.value" class="my-1">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import {seededShuffle} from '@/courses/utils/seededShuffle';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI51LBA2M2Q5',
  components: {
    seededShuffle,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      optionsA: [
        {text: 'All the carbons are nonequivalent.', value: 'all'},
        {text: 'There is one set of four equivalent carbons.', value: 'oneSet'},
        {text: 'There are two separate sets of equivalent carbons.', value: 'twoSet'},
      ],
      optionsB: [
        {text: 'yes', value: 'yes'},
        {text: 'no', value: 'no'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsAShuffled() {
      return seededShuffle(this.optionsA, this.seed);
    },
  },
};
</script>
